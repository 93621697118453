import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from 'components';
import { Header, Wysiwyg, TitleAndCta, ThreeColumnImages } from 'slices';
import headerImg from 'images/job-header-bg.png';

const Job = ({ data, pageContext }) => {
  const { uid, title, location, jobLink, departments, description, id } = pageContext;

  const [formattedDesc, setFormattedDesc] = useState(null);

  useEffect(() => {
    const decodeHtml = (text) => {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = text;
      return textArea.value;
    };
    const formattedDescription = decodeHtml(description);
    setFormattedDesc(formattedDescription);
  }, []);

  const headerData = {
    id: 'wysiwyg',
    primary: {
      title: {
        html: `<h1>${title}</h1>`,
      },
      content: {
        html: `<p>We're looking for a ${title} to join our team in ${location}</p>`,
      },
    },
  };

  const wysiwygData = {
    id: 'wysiwyg',
    primary: {
      content: {
        html: formattedDesc,
      },
      title: {
        html: '<h1>About the role</h1>',
      },
    },
  };

  const titleCtaData = {
    id: '219579a9-2c5f-59f8-9e08-a0a50f15dfeb',
    primary: {
      background: 'Grey',
      cta_link: {
        url: jobLink,
      },
      cta_text: {
        text: 'Apply now',
      },
      title: {
        html: '<h3>Does this sound like you?</h3>',
      },
    },
  };

  return (
    <Layout>
      <Header data={headerData} isJobs />
      <ThreeColumnImages isJobs />
      <Wysiwyg data={wysiwygData} id="wysiwyg" />
      <TitleAndCta data={titleCtaData} />
    </Layout>
  );
};

export default withPrismicPreview(Job);
